/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-color-secondary-light {
  --ion-color-base: var(--ion-color-secondary-light);
  --ion-color-base-rgb: var(--ion-color-secondary-light-rgb);
  --ion-color-contrast: var(--ion-color-secondary-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-light-shade);
  --ion-color-tint: var(--ion-color-secondary-light-tint);
}

.emergency-contanct-modal .modal-wrapper {
  height: 80%;
  width: 80%;
  border-radius: 12px;
}

.authorization-code-modal .modal-wrapper {
  height: 210px;
  width: 265px;
}

.alerts-modal .modal-wrapper {
  height: 50%;
  width: 90%;
  border-radius: 12px;
}

ion-modal.display-alert-modal {
  --max-height: 70%;
}

.display-alert-modal .modal-wrapper {
  border-radius: 5px;
  align-self: flex-end;
}

// TODO check if alert modal breaks
// ion-modal::part(content) {
//   /* Any custom styles here */
// }

// ion-modal::part(backdrop) {
//   /* Any custom styles here */
// }

* ::-webkit-scrollbar {
  display: none;
}

.chat-options-modal {
  ion-backdrop {
    background: rgba(0, 0, 0, 0.0);
  }

  .modal-wrapper {
    position: absolute;
    top: 4px;
    right: 4px;
    height: unset;
    width: unset;
    min-width: 40%;
    border-radius: 2px;
    box-shadow: 0 0 3px;

    .ion-page {
      display: contents;
    }
  }
}

.pictogram-select-modal {
  ion-backdrop {
    background: rgba(0, 0, 0, 0.0);
  }

  .modal-wrapper {
    position: absolute;
    bottom: 115px;
    left: 0px;
    max-height: 250px;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 3px;

    .ion-page {
      display: contents;
    }
  }
}

.pictogram-select-modal.keyboard-open {
  .modal-wrapper {
    bottom: 58px;
  }
}

ion-item {
  --min-height: 48px;
}

ion-alert.small-alert > div > div.alert-radio-group.sc-ion-alert-md > button {
  height: 35px;
}

ion-alert.small-alert > div > div.alert-radio-group.sc-ion-alert-ios > button {
  height: 35px;
}

.prefix-suffix {
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
}

.no-padding-grid {
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
}

ion-slides.swiper-container.full-height {
  height: 100%;
}

ion-item.region-item {
  --min-height: 55px;

  --ion-safe-area-right: 0px;
  --inner-padding-end: 0px;
  --padding-end: 0px;
}

ion-item.region-item.ios {
  --min-height: 100%;
}

ion-label.region-label {
  min-width: fit-content;
  margin-inline-end: 0px;
  flex: unset;
  text-align: right;
  margin-top: auto;
  margin-bottom: 8px;
}

ion-select.region-select {
  margin-inline-start: 8px;
  padding-inline-start: 0px;
  padding-inline-end: 30px;
  width: 100%;
  min-width: fit-content;
  max-width: 100%;

  text-align: center;
  margin-top: auto;
  margin-bottom: 8px;
  padding-bottom: 0px;
}

ion-item.phone-item {
  --min-height: 55px;

  --ion-safe-area-right: 0px;
  --inner-padding-end: 0px;
  --padding-end: 0px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-left: 16px;
  margin-top: 10px;
  text-align: start;
}

ion-toast.md.tabs-bottom {
  transform: translateY(-56px) !important;
}

ion-toast.ios.tabs-bottom {
  transform: translateY(-50px) !important;
}

ion-toast.button-secondary {
  --button-color: var(--ion-color-secondary);
}

ion-toast.md.chat-toast {
  transform: translateY(-112px) !important;
}

ion-toast.md.chat-toast.keyboard {
  transform: translateY(-56px) !important;
}

ion-toast.ios.chat-toast {
  transform: translateY(-106px) !important;
}

ion-toast.ios.chat-toast.keyboard {
  transform: translateY(-50px) !important;
}

button.alert-button.ion-focusable.ion-activatable.alert-primary {
  color: var(--ion-color-primary);
}

button.alert-button.ion-focusable.ion-activatable.alert-danger {
  color: var(--ion-color-danger);
}

.custom-grid {
  --ion-grid-padding: 0px;
  height: 100%;
  min-height: 100%;
  position: relative;
}

.infinite-scroll-loading > ion-infinite-scroll-content > div.infinite-loading {
  margin-bottom: 0px;
  margin-top: 8px;
}

app-webrtc.video-open ion-content.ios {

  visibility: hidden !important;

  :not(.keep-on-video) {
    visibility: hidden !important;

    video {
      visibility: visible !important;
    }
  }

  .keep-on-video * {
    visibility: visible !important;
  }

  .keep-on-video {
    visibility: visible !important;
  }

  .hide-on-video * {
    visibility: hidden !important;
  }

  .hide-on-video {
    visibility: hidden !important;
  }
}

.pictochat-sticker {
  width: 100%; // Can be set to a maximum of image size
}

emoji-mart.emoji-mart.emojis {
  width: 100%;
  margin-bottom: 5px;

  > div.emoji-mart {
    width: 100% !important;
    height: 100%;

    section.emoji-mart-scroll {
      height: 300px;
    }

    div.emoji-mart-bar:last-child {
      display: none;
    }
  }
}

emoji-mart.emoji-mart.emojis.small-container {
  > div.emoji-mart {
    section.emoji-mart-scroll {
      height: 150px;
    }
  }
}

.alert-checkbox-label.sc-ion-alert-md {
  white-space: normal !important;
}
